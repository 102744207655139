// @flow
import React, { Component } from 'react';
import Slider from 'react-slick';
import { imgBankUrl } from './constants';
import imagesJSON from './images.json';
import {
  __,
  filterConfigOptions,
  updateUrl,
  parseUrl,
  stripProductPrefixes,
} from './helpers';

import {
  Container,
  Left,
  Right,
  Configs,
  ProductImages,
  Bottom,
  SliderContainer,
} from './App.styles';

import AskForQuote from './AskForQuote';
import ChangeProduct from './ChangeProduct';
import Config from './Config';
import ProductImage from './ProductImage';
import SendPdfToEmail from './SendPdfToEmail';
import OpenMobileConfigs from './OpenMobileConfigs';
import MobileConfigButtons from './MobileConfigButtons';
import SliderArrow from './SliderArrow';

class App extends Component<
  { configs: Object, products: Array<string>, defaultProduct: string },
  {
    filteredConfigs: Object,
    product: string,
    selections: Object,
    mobileConfigsOpen: boolean,
    url: string,
    overrideAskForQuote: Object,
  }
> {
  state = {
    filteredConfigs: {},
    product: '',
    selections: {},
    mobileConfigsOpen: false,
    url: '',
    overrideAskForQuote: {
      hiddenInput: false,
      HTMLString: '',
    },
  };

  componentDidMount() {
    this.getAskForQuoteForm();

    const { configs, products, defaultProduct } = this.props;
    const parsedData = parseUrl({ configs, defaultProduct });
    const { product, selections, filteredConfigs } = parsedData;
    const selectedProduct = product || defaultProduct || products[0];
    this.setState({ filteredConfigs }, () => {
      if (products.constructor === Array && products.length) {
        this.selectProduct({
          name: 'product',
          value: selectedProduct,
          filteredConfigs,
        });
      }
      if (selections.constructor === Object && Object.keys(selections).length) {
        this.setState({ selections });
      } else {
        this.resetSelectionsToDefault();
      }
    });
  }

  componentDidUpdate() {
    const { product } = this.state;
    // Start updating url only after product has been set.
    if (product) {
      this.updateUrlWithData();
    }
  }

  updateUrlWithData = () => {
    const { product, selections, url: prevUrl } = this.state;
    const { overrideAskForQuote } = this.state;
    const { url } = updateUrl({ product, selections });
    if (url !== prevUrl) {
      this.setState({ url });
      // If we have overriden AskForQuote with a form from outside react, set url there.
      if (overrideAskForQuote.HTMLString) {
        if (false === overrideAskForQuote.hiddenInput) {
          overrideAskForQuote.hiddenInput = document.querySelector(
            '#module-configurator-root input.gform_hidden'
          );
          this.setState({ overrideAskForQuote });
          if (null === overrideAskForQuote.hiddenInput) {
            alert(
              __(
                'Ask for Quote form is not saving your current url correctly. Please insert it manually.'
              )
            );
          }
        }
        if (overrideAskForQuote.hiddenInput) {
          overrideAskForQuote.hiddenInput.value = url;
        }
      }
    }
  };

  /**
   * Reset selections to defaults, not product.
   */
  resetSelectionsToDefault = () => {
    const { filteredConfigs } = this.state;
    const configEntries = Object.entries(filteredConfigs);
    if (filteredConfigs.constructor === Object && configEntries.length) {
      const selections = {};
      configEntries.forEach(([key, value]) => {
        const [selection] = Object.keys(value.options);
        if (selection) {
          selections[key] = selection;
        }
      });
      this.setState({ selections });
    } else {
      console.error(__('configs seem to be missing?'));
    }
  };

  /**
   * Get image layers based on current selections.
   */
  getImages = () => {
    const {
      selections: {
        handedness,
        innerWallAndCeilingColor,
        model,
        tablePosition,
      },
    } = this.state;
    let {
      selections: { outerColor, furniture, floor },
    } = this.state;
    if (model) {
      outerColor = stripProductPrefixes(outerColor);
      furniture = stripProductPrefixes(furniture);
      return imagesJSON[
        `${model}_${handedness}_${floor}_${outerColor}_${innerWallAndCeilingColor}_${furniture}_${tablePosition}`
      ];
    }
  };

  selectConfig = ({ name, value }: { name: string, value: string }) => {
    let { selections } = this.state;
    // If tablePosition is "tableRight" then set handedness to "right" and vice versa
    // But only if value is not "tableMiddle" which is default value
    if ('tablePosition' === name && 'tableMiddle' !== value) {
      selections = {
        ...selections,
        handedness: 'tableRight' === value ? 'right' : 'left',
      };
    }
    // if handedness is "right" then set tablePosition to "tableRight" and vice versa
    // But only if tablePosition value is not "tableMiddle" which is default value
    if ('handedness' === name && 'tableMiddle' !== selections.tablePosition) {
      selections = {
        ...selections,
        tablePosition: 'right' === value ? 'tableRight' : 'tableLeft',
      };
    }
    selections = { ...selections, [name]: value };
    this.setState({ selections });
  };

  selectProduct = ({
    name,
    value,
    filteredConfigs: passedFilteredConfigs, // optional!
  }: {
    name: string,
    value: string,
    filteredConfigs?: Object,
  }) => {
    const { configs, products } = this.props;
    const { selections } = this.state;
    if ('product' === name) {
      const product = value;
      const { filteredConfigs } = passedFilteredConfigs
        ? { filteredConfigs: passedFilteredConfigs }
        : filterConfigOptions({
            configs,
            product,
          });
      // Clear each selection if not compatible with current product.
      const resetSelections = {};
      Object.entries(selections).forEach(([key, selection]) => {
        products.some((scopedProduct) => {
          const scopedSelection = selection.includes(scopedProduct);
          if (scopedSelection && product !== scopedProduct) {
            // First config value presumed to be safe.
            const [resetSelection] = Object.keys(filteredConfigs[key].options);
            resetSelections[key] = resetSelection;
          }
          // NOTE: only SOLO has tablePositions other than tableMiddle
          // if key is "tablePosition" and scopedProduct is !== "SOLO" then resetSelections[key] = "tableMiddle" which is default
          if ('tablePosition' === key && scopedProduct !== 'SOLO') {
            resetSelections[key] = 'tableMiddle';
          }
          return scopedSelection;
        });
      });
      this.setState({
        product,
        filteredConfigs,
        selections: { ...selections, ...resetSelections },
      });
    } else {
      console.error(__('Only use selectProduct for product selection'));
    }
  };

  /**
   * Toggle the visibility of mobile configs.
   */
  toggleMobileConfigs = () => {
    let { mobileConfigsOpen } = this.state;
    mobileConfigsOpen = !mobileConfigsOpen;
    document
      .getElementsByTagName('body')[0]
      .setAttribute('data-configurator-overlay', mobileConfigsOpen);
    this.setState({ mobileConfigsOpen });
  };

  /**
   * Try to get Ask for Quote form from page DOM. This is added with the configurator Gutenberg block.
   */
  getAskForQuoteForm = () => {
    const overrideAskForQuote = {
      hiddenInput: false,
      HTMLString: '',
    };
    const askForQuoteElement = document.getElementById(
      'module-configurator-quote'
    );

    if (askForQuoteElement) {
      askForQuoteElement.parentNode.removeChild(askForQuoteElement);
      askForQuoteElement.style.display = '';
      overrideAskForQuote.HTMLString = askForQuoteElement.innerHTML;
      this.setState({ overrideAskForQuote });
    }
  };

  LeftArrow = () => {
    const { slickNext, slickPause } = this.slider;
    return (
      <button
        type="button"
        onClick={() => {
          slickNext();
          slickPause();
        }}
        className="slick-prev"
      >
        <SliderArrow orientation="right" />
      </button>
    );
  };

  RightArrow = () => {
    const { slickPrev, slickPause } = this.slider;
    return (
      <button
        type="button"
        onClick={() => {
          slickPrev();
          slickPause();
        }}
        className="slick-next"
      >
        <SliderArrow orientation="left" />
      </button>
    );
  };

  render() {
    const { LeftArrow, RightArrow } = this;
    const { products } = this.props;
    const {
      product,
      filteredConfigs,
      selections,
      mobileConfigsOpen,
      url,
      overrideAskForQuote,
    } = this.state;
    const settings = {
      dots: false,
      infinite: true,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 2000,
      prevArrow: <LeftArrow />,
      nextArrow: <RightArrow />,
    };

    const images = this.getImages();
    return (
      <Container>
        <Left>
          <SliderContainer>
            <Slider ref={(slider) => (this.slider = slider)} {...settings}>
              {images &&
                images.map((img) => {
                  return (
                    <ProductImages>
                      {img.map((imagePart) => {
                        return (
                          <ProductImage
                            key={`${imgBankUrl}/${imagePart}`}
                            hidePrevious
                            src={`${imgBankUrl}/${imagePart}`}
                          />
                        );
                      })}
                    </ProductImages>
                  );
                })}
            </Slider>
          </SliderContainer>

          <OpenMobileConfigs handleClick={this.toggleMobileConfigs} />
          <Bottom>
            {/* TODO */ null && <SendPdfToEmail />}
            {overrideAskForQuote.HTMLString ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: overrideAskForQuote.HTMLString,
                }}
              />
            ) : (
              <AskForQuote url={url} />
            )}
          </Bottom>
        </Left>
        <Right>
          <ChangeProduct
            products={products}
            currentValue={product}
            handleChange={this.selectProduct}
          />
          <Configs mobileOpen={mobileConfigsOpen}>
            {Object.entries(filteredConfigs).map(
              ([key, { title, options }]) => {
                // ignore furnitureColor options for now
                if ('furnitureColor' === key) {
                  return false;
                }
                // If model is other than SOLO, then ignore tablePosition options
                // Check if model does not contain 'SOLO' and key is 'tablePosition'
                if (
                  'tablePosition' === key &&
                  selections.model &&
                  !selections.model.includes('SOLO')
                ) {
                  return false;
                }

                return (
                  <Config
                    key={key}
                    name={key}
                    title={title}
                    options={options}
                    currentValue={selections[key]}
                    handleChange={this.selectConfig}
                    disabled={
                      'furnitureColor' === key &&
                      selections.furniture &&
                      'none' === selections.furniture
                    }
                  />
                );
              }
            )}
            <MobileConfigButtons
              resetSelectionsToDefault={this.resetSelectionsToDefault}
              toggleMobileConfigs={this.toggleMobileConfigs}
            />
          </Configs>
        </Right>
      </Container>
    );
  }
}

export default App;
